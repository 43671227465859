.laptop {
  position: relative;
  margin: auto;
  max-width: 20rem;
  transform-style: preserve-3d;
  perspective: 900px;
}

.laptop__screen {
  z-index: 2;
  padding: 3%;
  border-radius: 1rem;
  background: linear-gradient(to bottom, #333, #111);
  box-shadow: 0 0.1rem 0 #cfcfcf;
  border: 2px solid #ccc;
  transform-origin: bottom;
  transform: rotateX(10deg); /* Screen appears slightly open */
  transition: transform 0.3s ease-out;
}

.laptop__screen img {
  display: block;
  max-width: 100%;
  max-height: 230px;
  background: #000;
}

.laptop__base {
  position: absolute;
  top: 100%;
  z-index: 1;
  height: 1rem;

  width: 100%;
  background: linear-gradient(
    to right,
    #c3cfdb 0%,
    #f6f9fc 10%,
    #f6f9fc 90%,
    #c3cfdb 100%
  );
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
}
