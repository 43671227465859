/* Mobile.module.css */
.mobile {
  position: relative;
  margin: 20px auto;
  width: 12rem;
  height: 25rem;
  background: linear-gradient(to bottom, #333, #111);
  border-radius: 20px;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.5);
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.mobile__screen {
  flex-grow: 1;
  background-color: #000;
  border-radius: 10px;
  margin: 10px;
  position: relative;
  overflow: hidden;
}

.mobile__screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.mobile__camera {
  width: 15px;
  height: 15px;
  background-color: #313131;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5),
    inset 0 1px 2px rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.mobile__camera::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3px #fff, 0 0 5px #fff;
}
