/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #FEE75C 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #FEE75C);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.search-loader {
  color: #ffffff;
  font-size: 12px;
  text-indent: -9999em;
  overflow: hidden;
  display: inline-block;
  margin-right: 2px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

/* Bounce Effect */

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 10px 10px white, inset 0 -10px 10px white;
  /* white padding effect */
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom Container */
.container {
  width: 100%;
  max-width: 1378px;
}

#background-image {
  background: url("../public/assets/images/bg/recent_project_bg.svg") no-repeat;
}

#background-image::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fee75c;
  opacity: 0.95;
}

.home_img {
  padding-top: 230px;
  background-image: url("./assets/images/home_front_design.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 777px;
}

@media (min-width: 2500px) {
  .home_img {
    padding-top: 230px;
    background-image: url("./assets/images/home_front_design.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container {
    max-width: 2400px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .container {
    max-width: 985px;
  }

  .home_img {
    padding-top: 230px;
    background-image: url("./assets/images/home_front_design.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 777px;
  }

  #about_bg {
    background-image: url("./assets/images/about_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    max-width: 730px;
  }

  .home_img {
    background-image: none;
    background-color: #1f1f1f;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .container {
    max-width: 390px;
    margin: auto;
  }

  .home_img {
    background-image: none;
    background-color: #1f1f1f;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .container {
    max-width: 340px;
    margin: auto;
  }

  .home_img {
    background-image: none;
    background-color: #1f1f1f;
  }
}

@media (min-width: 0px) and (max-width: 374px) {
  .container {
    max-width: 300px;
    margin: auto;
  }

  .home_img {
    background-image: none;
    background-color: #1f1f1f;
  }
}

#about_bg {
  background-image: url("./assets/images/about_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 777px;
}

@media (max-width: 768px) {
  #about_bg {
    background-image: none;
    background-color: #1f1f1f;
    height: min-content;
  }
}

#domain_img {
  background-image: url("./assets/images/domain2_bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

#package_img {
  background-image: url("./assets/images/package_img.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

#testimonial_bg {
  background-image: url("./assets/images/testimonials.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

#project_detail {
  background-image: url("/public/assets/images/bg/prod_detail_bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

#swiper_slide {
  height: auto;
}