.pc__screen {
  z-index: 2;
  padding: 3%;
  border-radius: 1rem;
  background: linear-gradient(to bottom, #333, #111);
  box-shadow: 0 0.1rem 0 #cfcfcf;
  border: 2px solid #ccc;
}

.pc__screen img {
  display: block;
  max-width: 100%;
  height: 10rem;
  object-position: top;
  object-fit: cover;
  background: #000;
}

.pc__bottom {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  width: 1rem;
  background: linear-gradient(
    to right,
    #d2dde9 0%,
    #f9fcff 15%,
    #e5ebf2 40%,
    #e5ebf2 60%,
    #f9fcff 85%,
    #d2dde9 100%
  );
}

.pc__bottom::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 20%;
  height: 0.7rem;
  border-radius: 0 0 0.2rem 0.2rem;
  background: linear-gradient(
    to right,
    #c3cfdb 0%,
    #f6f9fc 10%,
    #f6f9fc 90%,
    #c3cfdb 100%
  );
}

.pc__base {
  position: absolute;
  top: calc(100% + 2rem);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  height: 0.5rem;
  width: 3rem;
  background: linear-gradient(
    to right,
    #c3cfdb 0%,
    #f6f9fc 10%,
    #f6f9fc 90%,
    #c3cfdb 100%
  );
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
}
