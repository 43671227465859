@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Nunito Sans", sans-serif;
}
